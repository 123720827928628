<template>
  <div class="schedule_wrap" v-if="links">
    <div class="schedule" v-show="isShow">
    <div></div>
      <!-- 日程表头部 -->
      <div class="schedule_box schedule_header">
        <div class="schedule_box_item" :style="`width:${scheduleItemMeanWidth}px`">
          <span class="sbi_span">Time</span>
          <span class="sbi_span">时间</span>
        </div>
        <template v-if="weekData && weekData.length">
          <div v-for="week in weekData" :key="week.date" :class="week.className" :style="`width:${scheduleItemMeanWidth}px`">
            <span class="sbi_span">{{week.eText}}</span>
            <span class="sbi_span">{{week.text}}({{week.showDate}})</span>
          </div>
        </template>
        <template v-else>
          <div v-for="week in defaultWeekData" :key="week.day" :style="`width:${scheduleItemMeanWidth}px`" :class="toDay === week.day ? ['schedule_box_item', 'schedule_box_item_acitve'] : ['schedule_box_item']">
            <span class="sbi_span">{{week.eText}}</span>
            <span class="sbi_span">{{week.text}}</span>
          </div>
        </template>
        <!-- <div class="schedule_header_status" :style="{ right: statusRight }" v-if="showStudent && isShow">
          <img v-show="publishStatus" :src="planPublishStatusIcon" alt="">
          <img v-show="!publishStatus" :src="planNoPublishStatusIcon" alt="">
        </div> -->
      </div>

      <div class="schedule_box schedule_body" v-if="links" :style="`width:${scheduleItemMeanWidth * 8}px`">
        <!-- S 环节 -->
        <template v-if="links">
          <div
            v-for="(link, linkIndex) in links"
            :key="linkIndex"
            class="schedule_box_item"
          >
            <div class="schedule_box_item_first" :style="`width:${scheduleItemMeanWidth}px`">
              <p class="sbif_datetime">{{ link.startTime }}-{{ link.endTime }}</p>
              <p class="sbif_title">{{ link.processName }}</p>
            </div>

            <div
              v-for="(n, i) in 7"
              :key="i"
              @click.self="handleEditLink('insert', link, linkIndex, i)"
              class="schedule_box_item_link"
              :style="`width:${scheduleItemMeanWidth}px`"
            >
              <div
                v-for="(
                  linksActivityIndex, linksActivityIndexI
                ) in linksActivityIndexs[linkIndex]"
                :key="linksActivityIndexI"
                class="schedule_box_item_link_wrap"
              >
                <div
                  v-if="linksActivity[linkIndex][linksActivityIndexI][i]"
                  @click="
                    handleEditLink(
                      linksActivity[linkIndex][linksActivityIndexI][i],
                      link,
                      [linkIndex, linksActivityIndexI, i]
                    )
                  "
                >
                  <linkDetails
                    :key="linksActivity[linkIndex][linksActivityIndexI][i].id"
                    :linkIndex="i"
                    :linkIndexEnd="[links && links.length, linkIndex]"
                    :show="showStudent"
                    :havePlan="havePlan"
                    :isUse="isUse"
                    :linkDetailsData="linksActivity[linkIndex][linksActivityIndexI][i]"
                    :lastLinkDetailsData="linksActivity[linkIndex][linksActivityIndexI][i - 1]"
                    :nextLinkDetailsData="linksActivity[linkIndex][linksActivityIndexI][i + 1]"
                    :itemData="linksActivity[linkIndex][linksActivityIndexI][i]"
                    :scheduleItemMeanWidth="scheduleItemMeanWidthChange"
                    @lookTtemActivityDetails="lookTtemActivityDetails"
                    @activityDelete="activityDelete"
                  ></linkDetails>
                </div>
                <div v-else class="schedule_box_item_link_empty" :style="`width:${scheduleItemMeanWidth}px`" @click.self="handleEditLink('insert', link, linkIndex, i)"></div>
              </div>
            </div>
          </div>
        </template>
        <!-- E 环节 -->
      </div>
    </div>

    <insertTemplateDialog
      :editDialogData="editDialogData"
    ></insertTemplateDialog>
    <activityEditDialog
      :data="activityData"
      @activityEdit="activityEdit"
      :student="showStudent"
      :lookActivity="lookActivityData"
    ></activityEditDialog>
  </div>
</template>

<script>
import tool from '../../../utils/tool';
import linkDetails from '@/views/teachingProgram/components/linkDetails';
import insertTemplateDialog from '@/views/teachingProgram/dailyProcessList/insertTemplateDialog';
import activityEditDialog from '@/views/teachingProgram/components/activityEditDialog';
import planPublishStatusIcon from '@/assets/img/plan-publish-status-icon.png'
import planNoPublishStatusIcon from '@/assets/img/plan-no-publish-status-icon.png'
export default {
  props: {
    scheduleData: Object,
    showStudent: {
      type: Boolean,
      default: false
    },
    publishStatus: {
      type: Boolean,
      default: false
    },
    havePlan: {
      type: Boolean,
      default: false
    },
    isUse: {
      type: String,
      default: ''
    },
  },
  components: {
    linkDetails,
    insertTemplateDialog,
    activityEditDialog
  },
  data() {
    return {
      popoverVisible: true,
      links: [],
      linksActivity: [],
      linksActivityIndexs: [],
      linkDetailsData: {},
      editDialogData: {},
      activityData: {},
      student: {},
      toDay: 0,
      lookActivityData: {},
      weekData: [],
      scheduleItemMeanWidth: 0,
      resizeTimer: null,
      defaultWeekData: [
        {
          day: 1,
          eText: 'Monday',
          text: '周一'
        },
        {
          day: 2,
          eText: 'Tuesday',
          text: '周二'
        },
        {
          day: 3,
          eText: 'Wednesday',
          text: '周三'
        },
        {
          day: 4,
          eText: 'Thursday',
          text: '周四'
        },
        {
          day: 5,
          eText: 'Friday',
          text: '周五'
        },
        {
          day: 6,
          eText: 'Saturday',
          text: '周六'
        },
        {
          day: 0,
          eText: 'Sunday',
          text: '周日'
        }
      ],

      planPublishStatusIcon, //已推送icon
      planNoPublishStatusIcon, //未推送icon

      statusRight: '9px',
      isShow: false,
    };
  },
  computed: {
    scheduleItemMeanWidthChange(){
      console.log(this.scheduleItemMeanWidth)
      return this.scheduleItemMeanWidth;
    }
  },
  created() {

  },
  mounted() {
    let { fullPath } = this.$route;
    this.scheduleWidth();
    window.addEventListener('resize', () => {
      this.scheduleWidth();
    });
    // if (fullPath === '/teachingProgram') {

    // }
  },
  methods: {
    popoverClick(e) {
      // this.popoverVisible = true;
    },
    popoverClose() {
      this.linkDetailsData = {
        visible: false
      };
    },
    handleEditLink(scope, link, index, columnIndex) {
      if(this.havePlan == true && this.isUse === '0'){
        return
      }
      let { student } = this;
      console.log(student);
      if(this.showStudent){
        student = JSON.parse(JSON.stringify(student));
      }
      console.log(columnIndex)
      let _d = {};
      _d.link = link;
      if (scope === 'insert') {
        _d.dataType = 'insert';
        _d.dataIndex = 'index';
        _d.weekData = this.weekData[columnIndex] || { day: columnIndex == 6 ? 0 : (columnIndex+1) };
      } else {
        _d.dataType = 'update';
        _d.data = scope;
        _d.weekData = this.weekData[index[2]] || { day: columnIndex == 6 ? 0 : (columnIndex+1) };
      }
      this.activityData = {};
      this.$nextTick(() => {
        this.activityData = {
          ..._d,
          student: this.showStudent ? student : null,
          dataIndex: index,
        };
      });

    },
    /**
       * 星期转数组下标
       */
    weekToNumber(weekDay) {
      let n = null;
      switch (weekDay) {
        case '1':
          n = 6;
          break;
        case '2':
          n = 0;
          break;
        case '3':
          n = 1;
          break;
        case '4':
          n = 2;
          break;
        case '5':
          n = 3;
          break;
        case '6':
          n = 4;
          break;
        case '7':
          n = 5;
          break;
      }
      return n;
    },
    // 处理数据结构
    disposalActivity() {
      // 环节内数据
      let activity = [];
      // 环节层数
      let activitysIndex = [];
      this.links.forEach((link, linkIndex) => {
        let linkProcesses = link.processes;
        activity[linkIndex] = [];
        if (linkProcesses) {
          activitysIndex.push(linkProcesses.length);
          linkProcesses.forEach((item, index) => {
            activity[linkIndex][index] = [];
            item.map(item => {
              activity[linkIndex][index].push(item);
            });
          });
        } else {
          activitysIndex.push(0);
        }
      });
      activity = activity.map(item => {
        if (item.length === 0) {
          item[0] = new Array(7).fill(null);
        }
        return item;
      });
      console.log(activity)
      activity.map((item,i) => {
        item.map((info,j) => {
          let firstIndex = null
          let itemId = null
          info.map((result,k) => {
            if(result !== null && firstIndex == null){
              firstIndex = k
              itemId = result.uniteCode
            }
            if(result != null && info[k+1] === null && result.uniteCode == itemId  && k != 6 || (info[k+1] != null &&  result != null && info[k+1].uniteCode != itemId  && k != 6 ) || (result != null && k == 6)){
              result.firstIndex = firstIndex
              result.indexList = [i,j,k]
              firstIndex = null
              itemId = null
            }
          })
        })
      })
      this.$nextTick(() => {
        this.linksActivity = activity;
        this.linksActivityIndexs = activitysIndex;
        console.log(activity)
      });

    },
    // 活动保存触发
    activityEdit(_data) {
      let { data, dataType, dataIndex, link } = _data;
      let form = {};
      console.log(_data);
      form.id = data.id;
      form.dayProcessId = link.id;
      form.activityFrom = data.activitySource;
      form.fromType = data.fromType;
      form.resourceId = data.resourceId;
      form.courseName = data.couresTitle;
      form.activityName = data.activityTitle;
      form.activityImg = data.activityImageUrl;
      form.activityType = data.activityType;
      form.remark = data.comment;
      form.bgColor = data.bgColor;
      form.date = data.week;
      form.uniteCode = data.uniteCode;
      form.techPlanTeachStudent = data.techPlanTeachStudent;
      form.allStudent = data.allStudent;
      form.planTemplateId = data.planTemplateId;

      this.$emit('scheduleDataEdit', {
        data: form,
        dataType,
        dataIndex,
        link,
        _form: data
      });
    },
    // 流程时间下 活动从多到少排序
    handleScheduleDataSort(data) {
      if (!data) {
        return;
      }
      let dataArr = [];
      data.forEach((item, index) => {
        dataArr[index] = [];
        item.forEach(item1 => {
          if (item1) {
            dataArr[index].push(item1);
          }
        });
      });
      dataArr = dataArr.sort((a, b) => {
        return b.length - a.length;
      });
      return this.handleSetScheduleDataWeek(dataArr);
    },
    /**
       * 设置数组对应周数坐标
       */
    handleSetScheduleDataWeek(data) {
      let linkProcesses = [];
      data.map(item => {
        let itemArr = [];
        let week = [];
        item.forEach((item, index) => {
          week.push(item.planDays || item.planDay);
        });
        item.forEach(item => {
          item.week = week;
          itemArr.push(item);
        });
        return itemArr;
      });
      data = JSON.parse(JSON.stringify(data));
      data.forEach((item, index) => {
        linkProcesses[index] = new Array(7).fill(null);
        item.forEach(item1 => {
          // 周数转下标
          if (item1) {
            let weekIndex;
            // planDays 有星期为教学计划传过来的模版
            if (item1.planDays) {
              weekIndex = this.weekToNumber(item1.planDays);
            } else {
              // planDay有星期为周计划模版传过来的数据
              weekIndex = this.weekToNumber(item1.planDay);
            }
            linkProcesses[index][weekIndex] = item1;
          }
        });
      });

      return this.handleScheduleDataShift(linkProcesses);
      // return linkProcesses;
    },
    /**
     * 向上排序
     * @param {*} data
     * @return {*}
     */
    handleScheduleDataShift(data) {
      let _d;
      data.forEach((item, itemIndex) => {
        for (let i = itemIndex; i < data.length; i++) {
          let nextItemIndexs = [];
          data[i].forEach((item, index) => {
            if (item) {
              nextItemIndexs.push(index);
            }
          });

          let checkIndexs = [];
          nextItemIndexs.forEach(index => {
            if (data[itemIndex][index] === null) {
              checkIndexs.push(index);
            }
          });

          if (nextItemIndexs.length === checkIndexs.length) {
            checkIndexs.forEach(index => {
              data[itemIndex][index] = data[i][index];
              data[i][index] = null;
            });
          }
        }
      });
      _d = data.filter(item => {
        let nullArr = [];
        item.forEach(item1 => {
          if (item1 === null) {
            nullArr.push(item1);
          }
        });
        if (nullArr.length === 7) {
          return false;
        }
        return true;
      });
      return _d;
    },
    lookTtemActivityDetails(scope) {
      this.lookActivityData = {};
      this.$nextTick(() => {
        this.lookActivityData = scope;
      });
      // this.$emit('lookTtemActivityDetails', scope);
    },
    activityDelete(scope) {
      this.$emit('activityDelete', scope);
    },
    getToWeekDate(date) {
      console.log(date)
      let _date = new Date(date);
      let _dateDay = _date.getDay();
      let oneDate;
      let weekData = [];
      let toDay = new Date().getDay();
      let weekText = [
        { text: '周一', eText: 'Monday' },
        { text: '周二', eText: 'Tuesday' },
        { text: '周三', eText: 'Wednesday' },
        { text: '周四', eText: 'Thursday' },
        { text: '周五', eText: 'Friday' },
        { text: '周六', eText: 'Saturday' },
        { text: '周日', eText: 'Sunday' },
      ];
      if (_dateDay === 0) {
        _dateDay = 6;
      }
      oneDate = new Date(_date).getDate() - _dateDay + 1;
      oneDate = new Date(_date).setDate(oneDate);
      oneDate = tool.formatTime(oneDate);
      for (let i = 0; i < 7; i++) {
        let dateData = {
          date: oneDate,
          day: new Date(oneDate).getDay(),
        };
        console.log(dateData)
        dateData.showDate = dateData.date.split('-');
        dateData.showDate.shift();
        dateData.showDate = dateData.showDate.join('.');
        dateData.className = dateData.day === toDay ? 'schedule_box_item schedule_box_item_acitve' : 'schedule_box_item';
        weekData.push(dateData);
        oneDate = new Date(oneDate).setDate(new Date(oneDate).getDate() + 1);
        oneDate = tool.formatTime(oneDate);
      }
      weekData.map((item, index) => {
        item.text = weekText[index].text;
        item.eText = weekText[index].eText;
      });
      return weekData;
    },
    // /**
    //  * 教学计划页面控制高度
    //  */
    // scheduleHeight() {
    //   let main = document.querySelector('.layout-main');
    //   let courseTitle = document.querySelector('.course-wrapper-title');
    //   let courseBodyTop = document.querySelector('.search_main.search_main_week_project');
    //   let schedule = document.querySelector('.schedule_wrap');
    //   schedule.style.height = `${ main.offsetHeight - courseTitle.offsetHeight - courseBodyTop.offsetHeight }px`;
    // }
    scheduleWidth() {
      this.resizeTimer = setTimeout(() => {
        let schedule = document.querySelector('.schedule_wrap');
        let scheduleWrapWidth = schedule.offsetWidth;
        let mean = Math.trunc(scheduleWrapWidth / 8) - 2;
        console.log(scheduleWrapWidth);
        this.scheduleItemMeanWidth = mean;
        // console.log(window.innerWidth);
        if(window.innerWidth <= 1500){
          this.statusRight = '10px'
        } else if(window.innerWidth > 1500){
          this.statusRight = '9px'
        }
        this.$nextTick(() => {
          this.isShow = true
        })
      }, 100);
    }
  },
  watch: {
    scheduleData: {
      immediate: true,
      handler(data) {
        let { processes, d, student } = data;
        if (!processes) {
          return;
        }
        if (student && student.planDay) {
          this.weekData = this.getToWeekDate(student.planDay);
        } else {
          this.toDay = new Date().getDay();
        }

        processes.map(item => {
          item.startTime = tool.removeSecond(item.startTime);
          item.endTime = tool.removeSecond(item.endTime);
        });
        this.links = null;
        this.isShow = false
        this.$nextTick(() => {
          this.links = processes;
          this.d = d;
          this.student = student;
          this.links.map(item => {
            let linkProcesses = this.handleScheduleDataSort(item.processes);
            item.processes = linkProcesses;
            return item;
          });
          // 处理数据渲染视图
          this.disposalActivity();
        });
      }
    },
    showStudent: {
      immediate: true,
      handler(data) {
      }
    }
  }
};
</script>

<style lang="scss">
  // $itemWidth: 184px;
  $itemWidth: 128px;
  .flex_width_type {
    flex-grow: 0;
    flex-shrink: 0;
    // width: $itemWidth;
  }
  .text_omit {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .schedule_wrap {
    margin: 20px 0;


  }
  .schedule {
    padding: 40px 0;
    // width: $itemWidth * 8 + 10;
    max-height: 400px;
    overflow-y: scroll;
    overflow-x: auto;
    .schedule_box {
      display: flex;
      position: relative;
      &.schedule_header {
        .schedule_box_item {
          @extend .flex_width_type;
          padding: 10px 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: #000;
          font-size: 14px;
          font-weight: bold;
          background: #f9fafc;
          .sbi_span + .sbi_span {
            margin-top: 2px;
          }
        }
        .schedule_header_status{
          position: absolute;
          top: 0;
          right: 9px;
          width: 59px;
          height: 59px;
          z-index: 3;
          img{
            width: 100%;
            height: 100%;
          }
        }
      }
      &.schedule_body {
        display: flex;
        flex-direction: column;
        border: 1px solid #e6e7ee;
        .schedule_box_item {
          position: relative;
          @extend .flex_width_type;
          display: flex;
          width: 100%;
          .schedule_box_item_first {
            padding: 0 20px;
            min-height: 120px;
            box-sizing: border-box;
            @extend .flex_width_type;
            .sbif_datetime {
              display: flex;
              margin-bottom: 10px;
              align-items: center;
              justify-content: space-between;
              font-size: 14px;
              color: #595959;
            }
            .sbif_title {
              font-size: 16px;
              font-weight: bold;
            }
          }
          .schedule_box_item_link {
            @extend .flex_width_type;
            position: relative;
            padding: 0 0 30px;
            box-sizing: border-box;
            cursor: pointer;
            &:before {
              z-index: 1;
              position: absolute;
              top: 0;
              height: 100%;
              content: "";
              border-right: 1px solid #e6e7ee;
            }
            .sbil_link {
              position: relative;
              margin-bottom: 3px;
              .sbil_link_wrap {
                flex-shrink: 1;
                z-index: 10;
                min-height: 95px;
                position: relative;
                padding: 20px 15px;
                border-left: 4px solid;
                border-radius: 10px 20px 20px 10px;
                // overflow: hidden;
                box-sizing: border-box;

              }
              &.link_text_none{
                .sbil_link_wrap{
                  .sbil_link_title{
                    visibility: hidden;
                  }
                  .sbil_link_remark{
                    visibility: hidden;
                  }
                  .sbil_link_student{
                    visibility: hidden;
                  }
                }
              }
              &.link_first {
                .sbil_link_wrap {
                  border-radius: 10px 0 0 10px;
                }
              }
              &.link_mid {
                .sbil_link_wrap {
                  border: 0;
                  border-radius: 0;
                }
              }
              &.link_last {
                .sbil_link_wrap {
                  border: 0;
                  border-radius: 0 20px 20px 0;
                }
              }
              .sbil_link_width {
                position: absolute;
                left: 0;
                top: 0;
              }
              &:hover > .link_details {
                display: block;
              }
              .sbil_link_title {
                @extend .text_omit;
                min-height: 14px;
                font-size: 14px;
                font-weight: bold;
              }
              .sbil_link_remark {
                @extend .text_omit;
                margin-top: 6px;
                min-height: 13px;
                font-size: 13px;
              }
              .sbil_link_student {
                @extend .text_omit;
                margin-top: 10px;
                min-height: 12px;
                font-size: 11px;
                color: #696974;
              }
              .sbil_link_delete {
                position: absolute;
                top: 10px;
                right: 6px;
                width: 16px;
                height: 16px;
                background: url(../../../assets/img/del-icon.png);
                background-size: cover;
                opacity: 1;
                &.sbil_link_delete_none {
                  display: none;
                }
              }

            }
            &:hover .sbil_link_delete {
                opacity: 1;
              }
            .link_details {
              display: none;
              z-index: 11;
              position: absolute;
              top: 81px;
              left: 0;
              padding: 0;
              width: 450px;
              background: #fff;
              box-shadow: 0 4px 10px #0003;
              cursor: default;
              &.link_details_right {
                right: 0;
                left: auto;
                &::before {
                right: 40px;
                left: auto;
                }
                &::after {
                  right: 38px;
                  left: auto;
                }
              }
              &.link_details_top {
                top: initial;
                bottom: 100%;
                &::before {
                  top: auto;
                  bottom: -20px;
                  transform: rotate(180deg);
                }
                &::after {
                  top: auto;
                  bottom: -24px;
                  transform: rotate(180deg);
                }
              }
              &::before,
              &::after {
                z-index: 10;
                position: absolute;
                top: -20px;
                left: 40px;
                content: "";
                width: 0;
                height: 0;
                border-width: 10px;
                border-style: solid;
                border-color: transparent transparent #fff transparent;
              }
              &::after {
                z-index: 9;
                top: -24px;
                left: 38px;
                border-width: 12px;
                border-bottom-color: #9992;
              }
              .link_details_close {
                $xy: 24px;
                position: absolute;
                top: $xy;
                right: $xy;
                width: 12px;
                height: 12px;
                background: url(../../../assets/img/icon_close.png);
                cursor: pointer;
              }
              .link_details_title {
                padding: 20px;
                font-size: 18px;
                font-weight: bold;
                border-bottom: 1px solid #ececec;
              }
              .link_details_name {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
              .el-form {
                padding: 20px;
                .el-form-item {
                  margin-bottom: 0;
                  .el-form-item__label {
                    color: #000;
                  }
                  .el-form-item__content {
                    color: #999;
                  }
                  .el-form-item__content {
                    @extend .text_omit;

                  }
                  .link_details_image {
                    width: 200px;
                    border-radius: 20px;
                    > img {
                      width: 100%;
                      border-radius: 20px;
                    }
                  }
                }
              }
            }
          }
          .schedule_box_item_link_empty {
            margin-bottom: 3px;
            height: 95px;
          }
        }
        & > :first-of-type {
          .schedule_box_item_link,
          .schedule_box_item_first {
            padding-top: 20px;
            flex-grow: 0;
          }
        }
      }
      .schedule_box_item {
        width: $itemWidth;
        &.schedule_box_item_acitve {
          color: #fff;
          background: #597EF7;
        }
      }
    }
  }
</style>
