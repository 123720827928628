<template>
  <div
    :class="linkClass"
    :style="`width:${scheduleItemMeanWidth}px`"
  >
    <div class="sbil_link_wrap" :style="linkStyle">
      <div class="sbil_link_title" :style="{transform: 'translateX(-'+moveWidth+'px)','text-align': linkDetailsData.firstIndex !== undefined && linkDataIndex - linkDetailsData.firstIndex > 0 ? 'center' : 'left'}">
        <span>{{linkData.courseName}}</span>
      </div><!--<span style="font-weight: 400;">({{linkData.activityName}})</span>-->
      <div class="sbil_link_remark" :style="{transform: 'translateX(-'+moveWidth+'px)','text-align': linkDetailsData.firstIndex !== undefined && linkDataIndex - linkDetailsData.firstIndex > 0 ? 'center' : 'left'}">{{linkData.activityName}}</div>
      <div class="sbil_link_student" :style="{transform: 'translateX(-'+moveWidth+'px)','text-align': linkDetailsData.firstIndex !== undefined && linkDataIndex - linkDetailsData.firstIndex > 0 ? 'center' : 'left'}">{{linkData.studentStr}}</div>
      <div :class="classDelete" @click.stop="handleDelete"></div>
    </div>
    <div :class="linkDetailsClass" v-if="linkDetailsShow" @click.stop="handleLinkDetails">
      <!-- <div class="link_details_close" @click="popoverClose"></div> -->
        <div class="link_details_title">{{linkData.courseName}}</div>
        <el-form label-width="6.25vw">
          <el-form-item label="活动来源：">
            <el-button v-if="linkData.activityFrom === '0'" type="primary" size="mini" @click.stop="handleActivityDetails">课程资源</el-button>
            <div v-else @click.stop="handleActivityDetails">自定义</div>
          </el-form-item>
          <el-form-item label="活动名称：" ><div>{{linkData.activityName}}</div></el-form-item>
          <el-form-item label="课程图片：" v-if="linkData.activityImg">
            <div class="link_details_image" @click.stop="handleImage">
              <el-image :src="linkData.activityImg" :preview-src-list="[linkData.activityImg]"></el-image>
            </div>
          </el-form-item>
          <el-form-item label="学生姓名：" v-if="linkData.studentStr">
            <div class="link_details_name">{{linkData.studentStr}}</div>
          </el-form-item>
          <el-form-item label="时间范围：">{{valueToWeek(linkData.week)}}</el-form-item>
          <el-form-item label="备注：">{{ linkData.remark }}</el-form-item>
        </el-form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    linkDetailsData: Object,
    nextLinkDetailsData: Object,
    lastLinkDetailsData: Object,
    linkIndex: Number,
    itemData: Object,
    scheduleItemMeanWidth: Number,
    linkIndexEnd: {
      type: Array,
      default: [0, 0]
    },
    show: {
      type: Boolean,
      default: false
    },
    havePlan: {
      type: Boolean,
      default: false
    },
    isUse: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      popoverVisible: true,
      linkData: {},
      linkClass: [],
      linkDetailsClass: ['link_details'],
      linkStyle: '',
      nextLinkData: {},
      lastLinkData: {},
      linkDataIndex: 0,
      itemDataIndex: 0,
      linkDetailsShow: false,
      linkIndexY: [],
      classDelete: [],
      moveWidth: 0,
      firstIndex: 0
    };
  },
  created() {
    this.linkClass = ['sbil_link'];
    let { linkDetailsData, nextLinkDetailsData, lastLinkDetailsData, linkIndexY } = this;
    if (!lastLinkDetailsData) {
      lastLinkDetailsData = {};
    }
    if (!nextLinkDetailsData) {
      nextLinkDetailsData = {};
    }

    let firstCode = lastLinkDetailsData.uniteCode;
    let midCode = linkDetailsData.uniteCode;
    let lastCode = nextLinkDetailsData.uniteCode;

    this.classDelete = ['sbil_link_delete', 'sbil_link_delete_none'];
    if (firstCode == midCode && midCode == lastCode && firstCode == lastCode) {
      this.linkClass.push('link_mid');
      this.linkClass.push('link_text_none');
      this.moveWidth = 0
    } else if (firstCode == midCode && midCode != lastCode) {
      this.linkClass.push('link_last');
      this.moveWidth = ((this.linkDataIndex - linkDetailsData.firstIndex) * this.scheduleItemMeanWidth) / 2
      if(this.havePlan == true && this.isUse === '1' || !this.havePlan){
        this.classDelete.pop();
      }
    } else if (firstCode != midCode && midCode == lastCode) {
      this.linkClass.push('link_first');
      this.linkClass.push('link_text_none');
      this.moveWidth = 0
    } else {
      this.moveWidth = 0
      if(this.havePlan == true && this.isUse === '1' || !this.havePlan){
        this.classDelete.pop();
      }
    }
    // if (!this.linkDetailsShow) {
    //   this.classDelete = ['sbil_link_delete'];
    // }
    switch (this.linkDataIndex) {
      case 4:
      case 5:
      case 6:
        this.linkDetailsClass.push('link_details_right');
        break;
    }
    if (linkIndexY[0]) {
      let max = linkIndexY[0] - 3;
      if (max <= 0) {
        max = 0;
      }
      if (linkIndexY[1] > max && max > 2) {
        this.linkDetailsClass.push('link_details_top');
      }
    }
  },
  mounted() {
  },
  methods: {
    switchWeek(value) {
      let week = null;
      switch (value) {
        case '2':
          week = '周一';
          break;
        case '3':
          week = '周二';
          break;
        case '4':
          week = '周三';
          break;
        case '5':
          week = '周四';
          break;
        case '6':
          week = '周五';
          break;
        case '7':
          week = '周六';
          break;
        case '1':
          week = '周日';
          break;
      }
      return week;
    },
    valueToWeek(arr) {
      let weekArr = [];
      let week;
      if (arr && arr.length) {
        arr.forEach(item => {
          week = this.switchWeek(item);
          week && weekArr.push(week);
        });
      }
      return weekArr.join('，');
    },
    handleImage() {
    },
    handleLinkDetails() {

    },
    handleItemWidth() {
      let firstCode = this.lastLinkDetailsData ? this.lastLinkDetailsData.uniteCode : undefined;
      let midCode = this.linkDetailsData ? this.linkDetailsData.uniteCode : undefined;
      let lastCode = this.nextLinkDetailsData ? this.nextLinkDetailsData.uniteCode : undefined;
      if (firstCode == midCode && midCode == lastCode && firstCode == lastCode) {
        this.moveWidth = 0
      } else if (firstCode == midCode && midCode != lastCode) {
        this.moveWidth = ((this.linkDataIndex - this.linkDetailsData.firstIndex) * this.scheduleItemMeanWidth) / 2
        console.log(this.linkDataIndex)
      } else if (firstCode != midCode && midCode == lastCode) {
        this.moveWidth = 0
      } else {
        this.moveWidth = 0
      }
    },
    handleActivityDetails() {
      this.$emit('lookTtemActivityDetails', this.scope);
    },
    handleDelete() {
      this.$emit('activityDelete', this.scope);
    }
  },
  watch: {
    itemData: {
      immediate: true,
      handler(scope) {
        this.scope = scope;
      }
    },
    show: {
      immediate: true,
      handler(v) {
        this.linkDetailsShow = v;
      }
    },
    linkIndex: {
      immediate: true,
      handler(n) {
        if (n) {
          this.linkDataIndex = n;
        }
      }
    },
    linkIndexEnd: {
      immediate: true,
      handler(indexs) {
        this.linkIndexY = indexs;
      }
    },
    scheduleItemMeanWidth: {
      immediate: true,
      handler(n) {
        if (n) {
          console.log(n)
          setTimeout(() => {
            this.handleItemWidth()
          },100)
        }
      }
    },
    linkDetailsData: {
      immediate: true,
      handler(data) {
        let { techPlanTeachStudent, allStudent } = data;
        let studentNames = [];
        this.linkData = data;
        if (!techPlanTeachStudent) {
          techPlanTeachStudent = [];
        }
        techPlanTeachStudent.forEach(item => {
          // console.log(item);
          studentNames.push(item.studentName);
        });
        if(allStudent === '1'){
          this.linkData.studentStr = studentNames.join('，');
        }
        this.linkStyle = `background-color:${ this.linkData.bgColor }15;border-color:${ this.linkData.bgColor };`;
      }
    },
  }
};
</script>

<style lang="scss">
</style>
