<template>
  <div class="checkbox_week">
    <el-checkbox
      class=""
      @change="handleCheckAllChange"
      :indeterminate="isIndeterminate"
      v-model="checkAll">全选</el-checkbox>
    <el-checkbox-group v-model="check" @change="handleCheckedChange">
      <el-checkbox
        v-for="week in defaultCheckbox"
        :label="week.value"
        :key="week.value">{{ week.label }}</el-checkbox>
    </el-checkbox-group>
  </div>
</template>

<script>
export default {
  props: {
    group: {
      type: Array,
      required: true
    },
    checked: {
      type: Array,
    },
    valueName: {
      type: String,
      required: true
    },
    labelName: {
      type: String,
      default: 'label'
    }
  },
  data() {
    return {
      defaultCheckbox: [],
      defaultValueName: '',
      isIndeterminate: false,
      checkAll: false,
      check: [],
      checkCount: 0,
    };
  },
  methods: {
    /**
     * 全选
     */
    handleCheckAllChange(value) {
      this.check = value ? this.defaultCheckbox.map(item => item.value) : [];
      this.isIndeterminate = false;
      this.emitCheckData();
    },
    /**
     * 选择单点
     */
    handleCheckedChange(value) {
      const checkCount = value.length;
      this.checkAll = checkCount === this.defaultCheckbox.length;
      this.isIndeterminate = checkCount > 0 && checkCount < this.defaultCheckbox.length;
      this.$nextTick(() => {
        this.emitCheckData();
      })
    },
    /**
     * 返回时间范围
     */
    emitCheckData() {
      let row;
      let checkValue = [];
      let value = this.defaultValueName;
      let data = this.check.map(item => {
        row = false;
        this.defaultCheckbox.map(item1 => {
          if (item === item1[value]) {
            checkValue.push(item);
            let { value, label } = item1;
            row = item1;
          }
        });
        return row;
      });
      this.$emit('weekChecked', {
        checkValue,
        checkData: data,
      });
    }
  },
  watch: {
    group: {
      immediate: true,
      handler(data) {
        this.defaultCheckbox = data;
      }
    },
    valueName: {
      immediate: true,
      handler(name) {
        this.defaultValueName = name;
      }
    },
    checked: {
      immediate: true,
      handler(weekArr) {
        this.check = weekArr ? weekArr : [];
      }
    },
  }
};
</script>

<style lang="scss">
.checkbox_week {
  display: flex;
  .el-checkbox {
    margin: 0 20px 0 0;
  }
}
</style>
