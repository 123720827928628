<template>
  <div :class="wrapClassName">
    <el-radio-group
      :class="className"
      v-model="radioValue"
      @change="handleChangeRadio"
      size="mini"
      fill="#597EF7"
    >

      <el-radio-button v-if="radioAllVisible" label="all">全部</el-radio-button>

      <el-radio-button
        v-for="(radio, index) in radioGroup"
        :key="index"
        :label="radio.value">
        {{radio.name}}
      </el-radio-button>
    </el-radio-group>
    <div v-if="handleShow" class="radio_wrap_button_wrap" @click="handleCollapse">
      <el-button
        v-if="this.collapseStatus"
        class="radio_wrap_button"
        type="text"
        >收起
        <i class="el-icon-arrow-up el-icon--right"></i>
      </el-button>
      <el-button v-else class="radio_wrap_button" type="text"
        >展开
        <i class="el-icon-arrow-down el-icon--right"></i>
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    radio: Array,
    radioAll: {
      type: Boolean,
      default: false,
    },
    defaultValue: String,
    selectName: String,
  },
  data() {
    return {
      radioAllVisible: false,
      wrapClassName: ['radio_wrap'],
      className: ['radio_wrap_box'],
      radioGroup: [],
      radioValue: '',
      radioTypeName: '',
      id: null,
      collapseStatus: false,
      dom: null,
      handleShow: false,
    };
  },
  created() {

  },
  mounted() {
    let self = this;

    self.handleCollapse();

    window.addEventListener('resize', function() {
      self.$nextTick(() => {
        self.wrapClassName.push('radio_wrap_' + new Date().getTime());
        let radioWrap = document.querySelector('.' + self.wrapClassName[1] + ' .radio_wrap_box');
        self.dom = radioWrap;
        console.log(radioWrap);
        let radioWrapHeight = null;
        if (radioWrap) {
          radioWrapHeight = radioWrap.offsetHeight;
        } else {
          radioWrapHeight = 49;
        }
        if (radioWrapHeight > 50) {
          if (!self.handleShow) {
            self.handleShow = true;
          }

        } else {
          if (self.handleShow) {
            self.handleShow = false;
          }

        }
      });
    });
  },
  methods: {
    handleCollapse() {
      let className = ['radio_wrap_box'];
      this.collapseStatus = !this.collapseStatus;
      if (this.collapseStatus) {
        className.push('is_active');
      }
      this.className = className;
    },
    handleChangeRadio(value) {
      this.$emit('select', {
        valueTypeName: this.radioTypeName,
        value,
      });
    }
  },
  watch: {
    radio: {
      immediate: true,
      handler(data) {
        this.radioGroup = data;
      }
    },
    radioAll: {
      immediate: true,
      handler(data) {
        this.radioAllVisible = data;
      }
    },
    defaultValue: {
      immediate: true,
      handler(value) {
        this.radioValue = value;
      }
    },
    selectName: {
      immediate: true,
      handler(value) {
        this.radioTypeName = value;
      }
    }
  }
};
</script>

<style lang="scss">
.radio_wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  .radio_wrap_box {
    width: calc(100% - 70px);
    height: 26px;
    overflow: hidden;
    transition: 0.3s;
    &.is_active {
      height: auto;
    }
    .el-radio-button {
      margin: 0 10px 0 0;
      border-radius: 4px;
      overflow: hidden;
      &.is-active {
        .el-radio-button__orig-radio:checked + .el-radio-button__inner {
          color: #fff !important;
          background: #597ef7 !important;
        }
      }
      .el-radio-button__inner {
        border: 0;
      }
    }
  }
  .el-button--text {
    padding: 0;
    height: 26px;
    line-height: 26px;
  }
  .radio_wrap_button_wrap {
    height: 26px;
    line-height: 26px;
    .radio_wrap_button {
      font-weight: normal;
    }
  }
}
</style>
