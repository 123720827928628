<template>
  <div>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      :before-close="dialogBeforeClose"
      width="39.58333vw"
    >
      <el-form
        class="activity_form"
        ref="form"
        :model="form"
        :rules="rules"
        label-width="6.77083vw"
      >
        <el-form-item label="环节：">
          <p class="activity_form_link">
            {{link.processName}}
            <span>{{ link.startTime }}-{{ link.endTime }}</span>
          </p>
        </el-form-item>
        <el-form-item prop="activitySource" label="活动来源：">
          <el-radio-group v-model="form.activitySource">
            <el-radio label="0">从课程资源选择</el-radio>
            <el-radio label="1">自定义</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button
            class="activity_form_select_coures"
            icon="el-icon-plus"
            v-if="form.activitySource === '0'"
            @click="handleResource('select')"
          >选择资源</el-button>
        </el-form-item>
        <el-form-item
          v-if="form.activitySource === '0' && form.resourceId || form.activitySource === '1'"
          prop="couresTitle" label="课程/主题名称：">
          <el-autocomplete
            class="activity_form_input"
            v-model="form.couresTitle"
            placeholder="请填写课程/主题名称"
            :fetch-suggestions="serachCourseTitle"
            @select="handleSearchCourseTitle"
          >
            <template slot-scope="{item}">
              <div class="name">{{ item.courseName }}</div>
            </template>
          </el-autocomplete>
        </el-form-item>
        <el-form-item
          v-if="form.activitySource === '0' && form.resourceId || form.activitySource === '1'"
          label="活动名称：" prop="activityTitle"
        >
          <el-autocomplete
            class="activity_form_input"
            v-model="form.activityTitle"
            placeholder="请填写活动名称"
            :fetch-suggestions="serachActivityTitle"
            @select="handleSearchActivityTitle"
          >
            <template slot-scope="{item}">
              <div class="name">{{ item.activityName }}</div>
            </template>
          </el-autocomplete>
          <el-button
            v-if="form.activitySource === '0' && form.resourceId"
            @click="handleResource('search')"
            type="text"
            >查看资源</el-button
          >
          <!-- <div
            v-if="activityHistoryVisible"
            class="activity_form_input_history"
          >
            <p class="afih_title">历史搜索</p>
            <div class="afih_item">
              <el-button type="primary" size="small">历史1</el-button>
              <el-button type="primary" size="small">历史2</el-button>
              <el-button type="primary" size="small">历史3</el-button>
              <el-button type="primary" size="small">历史4</el-button>
            </div>
          </div> -->
        </el-form-item>
        <el-form-item label="活动图片：">
          <el-upload
            v-if="updateShow"
            class="activity_upload_wrap"
            action="#"
            accept="image"
            :http-request="uploadImage"
            :limit="1"
            :show-file-list="false"
          >
            <el-image
              v-if="form.activityImageUrl"
              class="activity_image"
              :src="form.activityImageUrl"
            ></el-image>

            <template v-else>
              <i class="activity_image_icon"></i>
              <div class="el-upload__text">
                <p class="activity_image_icon_text">上传图片</p>
                <p class="activity_image_hint">
                  建议比例5:3，单个文件不能超过2MB，仅限上传1张
                </p>
              </div>
            </template>
          </el-upload>
        </el-form-item>
        <el-form-item prop="week" label="时间范围：">
          <checkboxAll
            :group="checkData.group"
            :checkedGroup="form.week"
            :valueName="'value'"
            :labelName="'label'"
            @checked="weekCheck"
          >
          </checkboxAll>
        </el-form-item>
        <el-form-item
          v-if="student"
          prop="student" label="学生范围：">
          <checkboxAll
            :group="checkBoxStudents"
            :checkedGroup="checkStudents"
            :valueName="'studentLabel'"
            :labelName="'studentName'"
            @checked="studentsCheck"
          >
          </checkboxAll>
        </el-form-item>
        <el-form-item label="背景颜色：">
          <el-color-picker v-model="form.bgColor" :predefine="colors"></el-color-picker>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input
            class="activity_form_textarea"
            type="textarea"
            v-model="form.comment"
            maxlength="200"
            show-word-limit
            placeholder="请输入内容"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="dialogBeforeClose">取消</el-button>
        <el-button type="primary" @click="handleButton">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog
      class="dialog_search_activity_resource_wrap"
      title="查看资源"
      :visible.sync="dialogSearchActivityShow"
      :destroy-on-close="true"
      :before-close="dialogSearchActivityBeforeClose">
      <div class="dialog_search_activity_resource_body">
        <h2>{{dialogSearchActivityData.activityName}}</h2>
        <div class="dialog_search_activity_resource_content" v-html="dialogSearchActivityData.activityContent"></div>
      </div>
    </el-dialog>

    <selectTheResource
      :selectResource="selectResource"
      @selectResourceData="selectResourceData"
    ></selectTheResource>
    <publish-view :viewTitle="'查看资源'" :courseViewDialogVisible.sync="courseViewDialogVisible" :contentId="courseContentCatalogId"></publish-view>
    <el-dialog custom-class="course-content-dialog" :visible.sync="courseContentVisible" :show-close="false" :append-to-body="true" :destroy-on-close="true" @before-close="handleCourseContentClose" @close="handleCourseContentClose" fullscreen >
      <div slot="title" class="course-content-dialog-title">
        <span>查看资源</span>
        <div class="course-content-dialog-close-button" @click="handleCourseContentClose">返回</div>
      </div>
      <div class="course-content-dialog-body">
        <course-content :courseContentCatalogActivityId="courseContentCatalogId" :activityData="activityData" ></course-content>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import checkboxAllWeek from '@/views/teachingProgram/components/checkboxAllWeek';
import checkboxAll from '@/views/teachingProgram/components/checkboxAll';
import selectTheResource from '@/views/teachingProgram/components/selectTheResource';
import PublishView from '@/components/publishView/index.vue';
import CourseContent from '@/views/resourceManage/courseResource/courseContent.vue';
import tool from '../../../utils/tool';
export default {
  components: { checkboxAll, checkboxAllWeek, selectTheResource, PublishView, CourseContent },
  props: {
    data: Object,
    student: {
      type: Boolean,
      default: false,
    },
    lookActivity: Object,
  },
  data() {
    return {
      dialogSearchActivityShow: false,
      dialogSearchActivityData: {
        activityName: '',
        activityContent: '',
      },
      updateShow: false,
      courseViewDialogVisible: false,
      courseContentCatalogId: null,
      dialogTitle: '添加',
      dialogVisible: false,
      timeRange: [{}],
      activityHistoryVisible: false,
      selectResource: {},
      form: {
        // 活动来源
        activitySource: '0',
        // 来源资源类型
        fromType: '',
        // 课程/主题名称
        couresTitle: '',
        // 活动名称
        activityTitle: '',
        // 活动图片
        activityImageUrl: '',
        // 时间范围
        timeRange: '',
        // 时间范围
        week: [],
        // 背景颜色
        bgColor: '#56FF9C',
        // 备注
        comment: '',
        techPlanTeachStudent: [],
        resourceId: null,
        activityClassify: null,
        allStudent: 0,
      },
      formWeek: {},
      dataType: 'insert',
      dataIndex: '',
      dataScope: {},
      rules: {
        couresTitle: [
          { required: true, message: '请填写课程/主题名称', trigger: 'blur' },
          { max: 50, message: '课程/主题名称字数不能大于50个字符' }
        ],
        activityTitle: [
          { max: 50, message: '活动名称字数不能大于50个字符' }
        ],
        activitySource: [
          { required: true, message: '请选择活动来源', trigger: 'blur' },
        ],
        week: [{ required: true, message: '请选择时间范围', trigger: 'blur' }],
      },
      checkData: {
        group: [
          { label: '周一', value: '2' },
          { label: '周二', value: '3' },
          { label: '周三', value: '4' },
          { label: '周四', value: '5' },
          { label: '周五', value: '6' },
          { label: '周六', value: '7' },
          { label: '周日', value: '1' },
        ],
        groupWeekArr: ['1', '2', '3', '4', '5', '6', '7'],
        name: 'value',
      },
      checkBoxStudents: [],
      checkStudents: [],
      link: {},
      historyActivityTitleData: [],
      historyCourseTitleData: [],
      historyTitleData: [],
      onData: {},
      colors: [
        '#F98585',
        '#FA974A',
        '#FDCB58',
        '#82C43C',
        '#68D19A',
        '#4CC8BD',
        '#72CBFF',
        '#7293FF',
        '#B385F9',
        '#F67BA5',
        '#E78FF5',
        '#8694C8',
        '#75A7BF',
        '#E1B16E',
        '#C1CF5D'
      ],
      pageLoading: null, //页面loading

      courseContentVisible: false, //查看资源弹窗标识
      activityData: null, //查看资源课程id
    };
  },
  created() {

    this.$api.searchTeachingHistoryList()
      .then(result => {
        let { code, data } = result.data;
        if (code) {
          this.$message.errror(data);
          return;
        }
        this.historyTitleData = data;
      })
      .catch(err => {
        console.error(err);
      });
  },
  methods: {
    dialogBeforeClose() {
      this.dialogVisible = false;
      this.form.activitySource = '0';
      this.form.fromType = '';
      this.form.resourceId = null;
      this.form.couresTitle = '';
      this.form.activityTitle = '';
      this.form.activityImageUrl = '';
      this.form.timeRange = '';
      this.form.week = null;
      this.form.allStudent = 0;
      this.form.comment = '';
      this.$refs['form'].clearValidate();

    },
    // 输入框历史记录
    serachActivityTitle(queryString, fn) {
      let { historyTitleData } = this;
      fn(historyTitleData);
    },
    serachCourseTitle(queryString, fn) {
      let { historyTitleData } = this;
      fn(historyTitleData);
    },
    handleChangeCourseTitle(data) {
      console.log(data);
    },
    // 点击输入框框历史记录
    handleSearchActivityTitle(data) {
      this.form.activityTitle = data.activityName;
    },
    handleSearchCourseTitle(data) {
      this.form.couresTitle = data.courseName;
      this.$refs['form'].clearValidate();
    },
    weekCheck(data) {
      let { checkedValue } = data;
      let form = JSON.parse(JSON.stringify(this.form))
      form.week = checkedValue
      this.$set(this,'form',form)
      // this.form.week = checkedValue;
    },
    studentsCheck(data) {
      let { checkedValue } = data;
      let valueArr = [];
      checkedValue.forEach(item => {
        valueArr.push({ studentLabel: item });
      });
      if(checkedValue.length == this.checkBoxStudents.length){
        this.form.allStudent = 0
      } else {
        this.form.allStudent = 1
      }
      this.form.techPlanTeachStudent = valueArr;
      console.log(data);
    },
    handleResource(handleName) {
      switch (handleName) {
        case 'search':
          console.log(this.form);
          if (this.form.resourceId) {

            let { resourceId } = this.form;
            console.log(this.form.activityClassify)
            if (this.form.activityClassify === undefined || this.form.activityClassify === null || this.form.activityClassify === 'jt' || this.form.activityClassify === 'jy') {
              this.courseContentCatalogId = null;
              this.$nextTick(() => {
                this.courseContentVisible = true;
                this.$nextTick(() => {
                  this.courseContentCatalogId = resourceId;
                  this.activityData = JSON.parse(JSON.stringify(this.form))
                })
              });

            } else {
              this.dialogSearchActivityShow = true;
              this.$api.searchCourseContentActivityDetail({
                activityId: resourceId
              })
                .then(result => {
                  let { code, data } = result.data;
                  if (code) {
                    return;
                  }
                  if (data && data.length) {
                    data = data[0];
                    this.dialogSearchActivityData = {};
                    this.$nextTick(() => {
                      this.dialogSearchActivityData = data;
                    });

                  }
                })
                .catch(err => {
                  console.log(err);
                });
            }
          } else {
            this.$message.error('请选择课程资源');
          }
          break;
        case 'select':
          this.selectResource = {};
          break;
      }
      console.log();
    },
    //关闭弹窗操作
    handleCourseContentClose(){
      this.courseContentVisible = false
      this.$nextTick(() => {
        this.courseContentCatalogId = null
        this.activityData = null
      })
    },
    // 确定按钮
    handleButton() {
      this.$refs['form'].validate(v => {
        if (v) {
          let form = Object.assign({}, this.form);
          console.log(form);
          if (form.activitySource === '0' && !form.resourceId) {
            this.$message.error('请选择课程资源');
            return;
          }
          form.week = [...form.week];
          console.log(form);
          // return;
          let data = {
            data: form,
            dataType: this.dataType,
            dataIndex: this.dataIndex,
            link: this.link,
          };
          this.$emit('activityEdit', data);
          this.dialogBeforeClose();
        }
      });
    },
    selectResourceData(data) {
      let { course, activity } = data;
      console.log(data);
      this.$nextTick(() => {
        this.form.couresTitle = course.courseName;
        this.form.activityTitle = activity.activityName || activity.catalogueName;
        this.form.resourceId = activity.id;
        this.form.activityClassify = activity.activityClassify;
        this.form.fromType = activity.activityClassify == 'jt' || activity.activityClassify == 'jy' ? '0' : '1'
        this.form.activityType = activity.activityClassify == 'jt' || activity.activityClassify == 'jy' ? '0' : '1'
      });
    },
    /**
     * 查询班级学生
     * @param {*} data
     * @return {*}
     */
    serachClassStudent(data, dataType) {
      this.$api
        .searchStudents(data)
        .then(result => {
          let { code, data } = result.data;
          if (code) {
            return;
          }
          let { checkStudentList, noCheckStudentList } = data;
          let checked = [];
          this.checkStudents = [];
          if (dataType === 'update') {
            this.checkBoxStudents = [].concat(checkStudentList, noCheckStudentList);
            this.$nextTick(() => {
              if(checkStudentList.length == this.checkBoxStudents.length){
                this.form.allStudent = 0
              } else {
                this.form.allStudent = 1
              }
            })
          } else {
            this.checkBoxStudents = noCheckStudentList;
            checkStudentList = noCheckStudentList;
            this.form.techPlanTeachStudent = checkStudentList
            this.form.allStudent = 0
          }

          checkStudentList.forEach(item => {
            checked.push(item.studentLabel);
          });
          this.checkStudents = checked;
        })
        .catch(error => {});
    },
    /**
     * 上传图片
     */
    uploadImage(e) {
      console.log(e);
      this.pageLoading = this.$loading({
        lock: true,
        fullscreen: true,
        text: '上传中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let data = new FormData();
      let { file } = e;
      data.append('file', file);
      this.$api
        .uploadImage(data)
        .then(result => {
          let { code, data, msg } = result;
          if (code) {
            this.$message.error(msg);
            return;
          }
          this.$message.success('图片上传成功');
          this.form.activityImageUrl = data;
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.pageLoading.close()
        });
    },
    /**
     * 关闭查看资源弹窗
     * @param {*}
     * @return {*}
     */
    dialogSearchActivityBeforeClose() {
      this.dialogSearchActivityShow = false;
    },
    /**
     * 随机颜色
     * @param {*}
     * @return {string} #ffffff
     */
    randomColor() {
      let colors = ['#F98585', '#FA974A', '#FDCB58', '#82C43C', '#68D19A', '#4CC8BD', '#72CBFF', '#7293FF', '#B385F9', '#F67BA5', '#E78FF5', '#8694C8', '#75A7BF', '#E1B16E', '#C1CF5D'];
      let colorsLength = colors.length;
      return colors[Math.round(Math.random() * colorsLength)];
    }
  },
  watch: {
    data(_data) {
      if (!_data.link) {
        return;
      }
      this.dialogVisible = true;
      this.updateShow = false;
      this.$nextTick(() => {
        this.updateShow = true;
      });
      let { data, dataType, dataIndex, student, link, weekData } = _data;
      console.log(weekData)
      console.log(new Date().getDay())
      this.dataType = dataType;
      this.link = link;
      this.onData = data;
      if (student) {
        if (data) {
          student.planDataId = data.id;
        } else {
          student.planDayProcessId = link.id;
          delete student.planDataId;
        }
        let { year, week:weekNum } = tool.getYearWeek(student.planDay);
        if (dataType === 'insert') {
          student.planDay = weekData.date;
        } else {
          delete student.planDay;
        }
        student.year = year;
        student.weekNum = weekNum;
        this.serachClassStudent(student, dataType);
      }
      this.dataIndex = dataIndex;
      switch (dataType) {
        case 'insert': {
          this.dialogTitle = '添加';
          let dateWeek = weekData.day;//new Date().getDay()
          this.form.week = [this.checkData.groupWeekArr[dateWeek]];
          this.form.bgColor = this.randomColor();
          break;
        }
        case 'update':
          this.dialogTitle = '修改';
          this.dataScope = data;
          this.form.id = data.id;
          this.form.activitySource = data.activityFrom;
          this.form.fromType = data.fromType;
          this.form.resourceId = data.resourceId;
          this.form.bgColor = data.bgColor;
          this.form.couresTitle = data.courseName;
          this.form.activityTitle = data.activityName;
          this.form.activityImageUrl = data.activityImg;
          this.form.comment = data.remark;
          this.form.week = data.week || data.date || [];
          this.form.uniteCode = data.uniteCode;
          this.form.techPlanTeachStudent = data.techPlanTeachStudent || [];
          this.form.activityClassify = data.activityType;
          // this.form.allStudent = data.allStudent || 0;
          this.checkStudents = data.techPlanTeachStudent || [];
          console.log(data)
          break;
      }
    },
    lookActivity(scope) {
      if (!scope.id) {
        return;
      }
      if (scope.resourceId) {
        let { resourceId, activityType } = scope;
        if (activityType === 'jt') {
          this.courseContentCatalogId = null;
          this.$nextTick(() => {
            this.courseContentVisible = true;
            this.$nextTick(() => {
              this.courseContentCatalogId = resourceId;
              this.activityData = JSON.parse(JSON.stringify(scope))
            })
          });

        } else {
          this.dialogSearchActivityShow = true;
          this.$api.searchCourseContentActivityDetail({
            activityId: resourceId
          })
            .then(result => {
              let { code, data } = result.data;
              if (code) {
                return;
              }
              if (data && data.length) {
                data = data[0];
                this.dialogSearchActivityData = {};
                this.$nextTick(() => {
                  this.dialogSearchActivityData = data;
                });

              }
            })
            .catch(err => {
              this.$message.error(err.message);
              console.log(err);
            });
        }
      } else {
        this.$message.error('没有关联到课程');
      }
    }
  },
};
</script>

<style lang="scss">
.activity_form {
  .activity_form_select_coures {
    padding: 30px 0;
    width: 390px;
    color: #597ef7;
    border-color: #597ef7;
  }
  .activity_form_link {
    font-size: 16px;
    font-weight: bold;
    > span {
      color: #597ef7;
      font-weight: normal;
    }
  }
  .activity_upload_wrap {
    width: 390px;
    height: 234px;
    text-align: center;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    background: #fafafa;
    box-sizing: border-box;
    overflow: hidden;
    .activity_image {
      width: 100%;
      height: 100%;
    }
    .activity_image_icon {
      margin: 60px auto 0;
      display: block;
      width: 48px;
      height: 48px;
      background: url(../../../assets/img/upload-img-icon.png);
      background-size: cover;
    }
    .activity_image_icon_text {
      font-size: 16px;
    }
    .activity_image_hint {
      color: #919191;
    }
  }
  .activity_form_textarea {
    .el-textarea__inner {
      height: 200px;
    }
    .el-input__count::before {
      content: "已输入";
    }
  }
  .activity_form_input {
    margin-right: 20px;
    width: 390px;
  }
  .activity_form_input_history {
    z-index: 10;
    position: absolute;
    width: 390px;
    background: #fff;
    box-shadow: 0 2px 8px #0001;
    .afih_title {
      padding: 6px 20px;
      font-size: 16px;
    }
    .afih_item {
      display: flex;
      padding-bottom: 10px;
      flex-direction: column;
      .el-button {
        margin: 0 20px 10px;
        &.el-button--primary {
          font-size: 14px;
          font-weight: normal;
          color: #595959;
          text-align: left;
          border: 0;
          border-radius: 20px;
          background: #f9f9f9;
        }
      }
    }
    .afih_clear {
      .el-button {
        width: 100%;
        font-weight: normal;
        border: 0;
        border-top: 1px solid #e7e7e7;
        border-radius: 0;
        &:hover {
          background: #fff;
        }
        &:focus {
          background: #fff;
        }
      }
    }
  }
}
.dialog_search_activity_resource_wrap {
  .dialog_search_activity_resource_body {
    > h2 {
      margin-bottom: 20px;
      font-size: 16px;
    }
    .dialog_search_activity_resource_content{
      color: #707070;
      font-size: 14px;
      video,
      audio {
        margin: 20px 10px;
      }
    }
  }
}
.course-content-dialog{
  overflow: hidden;
  .el-dialog__body{
    height: calc(100% - 115px);
    overflow: hidden;
    overflow-y: auto;
  }
}
.course-content-dialog-title{
  font-size: 16px;
  font-weight: bold;
  color: #444444;
  .course-content-dialog-close-button{
    position: absolute;
    top: 0;
    right: 20px;
    font-size: 18px;
    color: #597EF7;
    font-weight: 400;
    cursor: pointer;
  }
}
</style>
