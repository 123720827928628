<template>
  <el-dialog class="dialog_resource"
             title="选择资源"
             :visible.sync="dialogVisible"
             :before-close="dialogBeforeClose"
             fullscreen>
    <div class="dialog_resource_body">
      <el-form label-width="5.20833vw">
        <el-form-item label="课程分类：">
          <selectTheResourceRadio :defaultValue="classify.courseClassify"
                                  selectName="courseClassify"
                                  @select="selectRadio"
                                  :radio="typeOptions"
                                  radioAll></selectTheResourceRadio>
        </el-form-item>
        <el-form-item label="选项领域：">
          <selectTheResourceRadio :defaultValue="classify.fieldOfOptions"
                                  selectName="fieldOfOptions"
                                  @select="selectRadio"
                                  :radio="areaOptions"
                                  radioAll></selectTheResourceRadio>
        </el-form-item>
        <el-form-item label="课程系列：">
          <selectTheResourceRadio @select="selectRadio"
                                  selectName="courseTheme"
                                  :defaultValue="classify.courseTheme"
                                  :radio="searchThemeOptions"></selectTheResourceRadio>
        </el-form-item>
        <el-form-item label="版本：">
          <selectTheResourceRadio @select="selectRadio"
                                  selectName="courseVersion"
                                  :defaultValue="classify.courseVersion"
                                  :radio="versionOptions"></selectTheResourceRadio>
        </el-form-item>
        <el-form-item label="阶段：">
          <selectTheResourceRadio @select="selectRadio"
                                  selectName="scope"
                                  :defaultValue="classify.scope"
                                  :radio="scopeOptions"></selectTheResourceRadio>
        </el-form-item>
        <!-- <el-form-item label="课程等级：">
					<selectTheResourceRadio
					@select="selectRadio"
					selectName="courseGrade"
					:defaultValue="classify.courseGrade" :radio="levelOptions" radioAll></selectTheResourceRadio>
				</el-form-item> -->
        <el-form-item label="课程：">
          <selectTheResourceRadio @select="selectRadio"
                                  selectName="course"
                                  :radio="courseOptinons"
                                  :defaultValue="classify.course"></selectTheResourceRadio>
        </el-form-item>
        <el-form-item label="活动类型：">
          <selectTheResourceRadio @select="selectRadio"
                                  selectName="activityType"
                                  :defaultValue="classify.activityType"
                                  :radio="activityOptinons"
                                  radioAll></selectTheResourceRadio>
        </el-form-item>
      </el-form>

      <el-row class="course_content_wrap"
              v-if="isShow">
        <el-col :span="4"
                v-if="courseData.length > 0">
          <el-menu default-active=""
                   :default-openeds="defaultOpeneds"
                   :unique-opened="true"
                   ref="catalogMenu">
            <el-submenu v-for="(course, courseIndex) in courseData"
                        :index="courseIndex.toString()"
                        :key="courseIndex"
                        @select="handleMenuItem(courseActivity, 11)">
              <template slot="title">
                <el-tooltip class="item"
                            effect="light"
                            popper-class="customPopper"
                            :content="course.courseThemeName"
                            placement="top-start">
                  <div class="activity-course-title">{{course.courseThemeName}}</div>
                </el-tooltip>
              </template>
              <el-menu-item v-for="(courseActivity, courseActivityIndex) in courseActivityData[courseIndex]"
                            :index="`${courseIndex}_${courseActivityIndex}`"
                            :key="`${courseIndex}_${courseActivityIndex}`"
                            @click="handleMenuItem(courseActivity)">{{courseActivity.catalogueName ? courseActivity.catalogueName : courseActivity.activityName}}</el-menu-item>
            </el-submenu>
          </el-menu>
        </el-col>

        <el-col v-if="activityDetails || activityCatalogueDetails"
                class="ccw_content"
                :span="20"
                v-show="isDetailShow">
          <template v-if="activityDetails">
            <div class="ccw_content_title">
              <h1 v-if="activityDetails">{{activityDetails.activityName}}</h1>
              <el-button @click="handleCheckCourse"
                         type="primary"
                         size="small">确定</el-button>
            </div>
            <div class="ccw_content"
                 v-if="activityDetails.activityContent">
              <div class="w-e-text-container">
                <div class="w-e-text"
                     v-html="activityDetails.activityContent"></div>
              </div>
            </div>
          </template>
          <template v-if="activityCatalogueDetails">
            <div class="ccw_content_title">
              <h1>{{emitCourseData.activity.catalogueName}}</h1>
              <el-button @click="handleCheckCourse"
                         type="primary"
                         size="small">确定</el-button>
            </div>
            <el-collapse class="ccw_collapse"
                         v-model="showItem">
              <el-collapse-item v-for="row in activityCatalogueDetails"
                                :key="row.id"
                                :title="row.contentName"
                                :name="row.id">
                <template v-if="row.contentType === '0'">
                  <div class="ccw_collapse_text"
                       v-for="textContent in row.eduCourseThemeContent"
                       :key="textContent.id">
                    <div class="w-e-text-container"
                         v-if="textContent.content">
                      <div class="w-e-text"
                           v-html="textContent.content"></div>
                    </div>
                  </div>
                  <div class="ccw_collapse_files">
                    <div class="ccw_collapse_file"
                         v-for="(file, fileIndex) in row.eduCourseThemeCatalogueContentFile"
                         :key="fileIndex">
                      <div class="ccw_collapse_file_body">
                        <div class="ccw_collapse_file_icon">
                          <i class="excel"
                             v-if="checkFileType(file.originalName) === 'excel'"></i>
                          <i class="word"
                             v-if="checkFileType(file.originalName) === 'word'"></i>
                          <i class="pdf"
                             v-if="checkFileType(file.originalName) === 'pdf'"></i>
                          <i class="ppt"
                             v-if="checkFileType(file.originalName) === 'ppt'"></i>
                        </div>
                        <div class="ccw_collapse_file_name">{{file.originalName}}</div>
                      </div>
                      <div class="ccw_collapse_file_footer">
                        <el-tooltip class="item"
                                    effect="light"
                                    popper-class="customPopper"
                                    content="附件下载"
                                    placement="bottom"
                                    v-if="file.isDownload == '1'">
                          <img :src="fileDownloadIcon"
                               alt=""
                               @click="fileView(file,'download')"
                               style="margin-right: 0.41667vw"
                               v-if="file.isDownload == '1'" />
                        </el-tooltip>
                        <el-divider direction="vertical"
                                    v-if="file.isDownload == '1'"></el-divider>
                        <el-tooltip class="item"
                                    effect="light"
                                    popper-class="customPopper"
                                    content="附件预览"
                                    placement="bottom">
                          <img :src="fileViewIcon"
                               alt=""
                               @click="fileView(file,'view')"
                               style="margin: 0 0.26042vw 0 0.41667vw" />
                        </el-tooltip>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else-if="row.contentType === '1'">
                  <div class="ccw_collapse_videos">
                    <div class="ccw_collapse_video"
                         v-for="(video, videoIndex) in row.eduCourseThemeCatalogueContentVideo"
                         :key="videoIndex"
                         @click="videoView(video)">
                      <div class="ccw_collapse_video_box"
                           :style="{ 'background-image': 'url(' + (video.fontImg ? video.fontImg : defaultImg) + ')', 'background-size': '100% 100%', }">
                        <img :src="videoPlayIcon"
                             alt="" />
                      </div>
                      <div class="ccw_collapse_video_info_box">
                        <div class="ccw_collapse_video_name">{{video.videoName}}</div>
                        <div class="ccw_collapse_video_info">{{video.intro}}</div>
                        <div class="ccw_collapse_video_date">{{video.createTime}}</div>
                      </div>
                    </div>
                  </div>
                </template>
                <!-- <template v-else-if="row.contentType === '2'">
                  <el-tabs class="ccw_collapse_activitys">
                    <el-tab-pane class="ccw_collapse_activity" v-for="(eduActivity, eduActivityIndex) in row.eduActivity" :key="eduActivityIndex" :label="activityOptinonsIndex[eduActivity.activityClassify].name" :name="eduActivityIndex.toString()">
                      <span class="ccw_collapse_activity_name">{{eduActivity.activityName}}</span>

                      <div class="ccw_collapse_activity_content" v-if="eduActivity.activityContent">
                        <div class="w-e-text-container">
                          <div class="w-e-text" v-html="eduActivity.activityContent"></div>
                        </div>
                      </div>
                    </el-tab-pane>
                  </el-tabs>
                </template> -->
              </el-collapse-item>
            </el-collapse>
          </template>

        </el-col>
        <el-col :span="courseData.length > 0 ? 20 : 24"
                v-if="courseData.length == 0 || (!activityDetails && !activityCatalogueDetails && isDetailShow)">
          <div class="empty-content-panel"
               style="height: 18.22917vw">
            <img class="empty-img"
                 :src="emptyImg"
                 alt="" />
            <div class="empty-title">暂无活动内容</div>
          </div>
        </el-col>
      </el-row>

    </div>
    <!-- <div v-if="activityDetails || activityCatalogueDetails" slot="footer">
      <el-button type="primary" @click="handleCheckCourse" size="small">确定</el-button>
    </div> -->
    <el-image style="width: 0; height: 0;"
              ref="viewImg"
              :src="viewUrl"
              :preview-src-list="viewUrlList"></el-image>
    <video-view-dialog ref="videoView"
                       :videoViewDialogVisible.sync="videoViewDialogVisible"
                       :type="videoViewType"
                       :videoUrl="videoViewUrl"
                       @closeDialog="videoViewClose"></video-view-dialog>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex';
import selectTheResourceRadio from '@/views/teachingProgram/components/selectTheResourceRadio';
import emptyImg from '@/assets/img/empty-img.png';
import defaultImg from '@/assets/img/default-img.png';
import videoPlayIcon from '@/assets/img/play-icon.png';
import fileViewIcon from '@/assets/img/file-view-icon.png';
import fileDownloadIcon from '@/assets/img/file-download-icon.png';
import VideoViewDialog from '@/components/videoView/index.vue';
import { getFileViewUrl, exportFile } from "@/utils/fileUtils.js";
export default {
  props: {
    selectResource: Object
  },
  components: {
    selectTheResourceRadio,
    VideoViewDialog,
  },
  data () {
    return {
      dialogVisible: false,
      classify: {
        courseClassify: 'all',
        fieldOfOptions: 'all',
        scope: '',
        courseTheme: '',
        courseVersion: '',
        ageLevel: 'all',
        courseGrade: 'all',
        course: '0',
        activityType: 'all',
      },
      scopeOptions: [],
      versionOptions: [],
      searchThemeOptions: [],
      courseOptinons: [],
      activityOptinons: [],
      activityOptinonsIndex: {},
      // 课程数据
      courseData: [],
      courseOpenMenu: [],
      // 课程活动数据
      courseActivityData: [],
      // 课程活动详情
      courseActivityDetail: {},
      emitCourseData: {
        course: {},
        activity: {}
      },
      // 默认打开的目录
      defaultOpeneds: [],
      // 课程目录id列表
      courseIdList: [],
      activityDetails: null,
      activityCatalogueDetails: null,

      emptyImg, //空内容图片
      videoPlayIcon, // 视频icon
      defaultImg, // 默认图片
      fileViewIcon, // 文件预览icon
      fileDownloadIcon, //文件下载icon

      fileTypeList: ['png', 'jpg', 'jpeg'], // 文件类型list

      videoViewDialogVisible: false, // 视频预览标识
      videoViewType: '', // 视频类型
      videoViewUrl: '', // 视频预览url

      viewUrl: "",
      viewUrlList: [],

      isShow: false,
      isDetailShow: true,
      showItem: []
    };
  },
  async created () {
    // 活动类型字典
    await this.$api.getActivityDict()
      .then(result => {
        let { code, data } = result.data;
        if (code) {
          return;
        }
        let index = data.findIndex((item) => { return item.name == '家园活动' })
        if (index != -1) {
          data.splice(index, 1)
        }
        data.push({ name: '家园活动', value: 'jy', type: 'activity_classify' })
        let activityOptinonsIndex = {};
        data.forEach((item, index) => {
          activityOptinonsIndex[item.value] = item;
        });
        this.activityOptinons = data;
        console.log(data)
        this.activityOptinonsIndex = activityOptinonsIndex;
        data.map(item => {
          this.linkValue[item.value] = item.name;
        });

      })
      .catch(error => { });
    // await this.searchCourseContentActivityAllList();
  },
  methods: {
    getFileViewUrl,
    exportFile,
    // 视频预览
    videoView (videoInfo) {
      this.videoViewDialogVisible = true;
      this.$nextTick(() => {
        let type = videoInfo.videoUrl.substr(videoInfo.videoUrl.lastIndexOf('.') + 1, videoInfo.videoUrl.length);
        console.log(type);
        this.videoViewType = type == 'mp4' || type == 'avi' ? 'video' : type == 'mp3' ? 'audio' : '';
        this.videoViewUrl = videoInfo.videoUrl;
      });
    },
    // 视频预览关闭
    videoViewClose () {
      this.videoViewDialogVisible = false;
      this.$nextTick(() => {
        this.videoViewType = '';
        this.videoViewUrl = '';
      });
    },
    // 预览文件
    fileView (fileInfo, type) {
      if (type == 'view') {
        let type = fileInfo.fileUrl.substr(fileInfo.fileUrl.lastIndexOf('.') + 1, fileInfo.fileUrl.length);
        if (this.fileTypeList.indexOf(type.toLowerCase()) != -1) {
          this.viewUrl = fileInfo.fileUrl;
          this.viewUrlList = [fileInfo.fileUrl];
          this.$nextTick(() => {
            this.$refs.viewImg.showViewer = true;
          });
        } else {
          const { originalName, fileVersionId } = fileInfo
          this.getFileViewUrl('', { fileVersionId, name: originalName }, (data) => {
            if (data) {
              sessionStorage.setItem('viewUrl', data);
              let routeUrl = this.$router.resolve({ path: '/fileView.html' });
              window.open(routeUrl.href, '_blank');
            }
          });
        }
      } else {
        if (fileInfo.isDownload === '1') {
          let type = fileInfo.fileUrl.substr(fileInfo.fileUrl.lastIndexOf('.') + 1, fileInfo.fileUrl.length);
          if (this.fileTypeList.indexOf(type.toLowerCase()) != -1) {
            this.$message.warning('该附件为图片，只可预览查看');
          } else {
            // window.location.href = fileInfo.fileUrl;
            this.exportFile(fileInfo.fileUrl, {}, fileInfo.originalName)
          }
        } else {
          this.$message.warning('该附件不支持下载！');
        }
      }
    },
    checkFileType (fileName) {
      let type = fileName.match(/\.(xlsx?)$/i);
      // type = type.pop();
      if (type) {
        return 'excel';
      }
      type = fileName.match(/\.(docx?)$/i);
      if (type) {
        return 'word';
      }
      type = fileName.match(/\.(pptx?)$/i);
      if (type) {
        return 'ppt';
      }
      type = fileName.match(/\.(pdf)$/i);
      if (type) {
        return 'pdf';
      }
      return false;
    },
    //获取筛选的阶段list
    getScopeList (callback) {
      let params = {
        courseClassify: this.classify.courseClassify === 'all' ? '' : this.classify.courseClassify,
        courseField: this.classify.fieldOfOptions === 'all' ? '' : this.classify.fieldOfOptions,
        courseTheme: this.classify.courseTheme,
        courseVersion: this.classify.courseVersion,
      }
      this.$api.getSearchResourseDictionary(params).then((res) => {
        if (res.data.code === 0) {
          let scopeOptions = []
          res.data.data.map((item) => {
            let info = JSON.parse(JSON.stringify(item))
            info.value = item.type + '#' + info.value
            scopeOptions.push(info)
          })
          this.scopeOptions = scopeOptions
          callback && callback()
        } else {
          this.$message.error(res.data.msg);
        }
      })
    },
    //获取筛选的版本list
    getVersionList (callback) {
      let params = {
        courseClassify: this.classify.courseClassify === 'all' ? '' : this.classify.courseClassify,
        courseField: this.classify.fieldOfOptions === 'all' ? '' : this.classify.fieldOfOptions,
        courseTheme: this.classify.courseTheme,
      }
      this.$api.getSearchResourseVersions(params).then((res) => {
        if (res.data.code === 0) {
          this.versionOptions = res.data.data
          callback && callback()
        } else {
          this.$message.error(res.data.msg);
        }
      })
    },
    //获取筛选的系列list
    getThemeList (callback) {
      let params = {
        courseClassify: this.classify.courseClassify === 'all' ? '' : this.classify.courseClassify,
        courseField: this.classify.fieldOfOptions === 'all' ? '' : this.classify.fieldOfOptions,
      }
      this.$api.getSearchResourseTheme(params).then((res) => {
        if (res.data.code === 0) {
          this.searchThemeOptions = res.data.data
          callback && callback()
        } else {
          this.$message.error(res.data.msg);
        }
      })
    },
    /**
     * 关闭浮窗
     */
    dialogBeforeClose () {
      this.dialogVisible = false;
    },
    /**
     * @description 选择按钮
     * @description data.valueTypeName 选择名称
     * @description data.value 选择值
     * @param {object} data
     * @param {string} data.valueTypeName 选择名称
     * @param {string} data.value 选择值
     */
    selectRadio (data) {
      this.activityDetails = null;
      this.activityCatalogueDetails = null;
      this.isShow = false
      let { value, valueTypeName } = data;
      this.courseActivityData = [];
      this.classify[valueTypeName] = value;
      if (valueTypeName == 'scope') {
        let valueList = value.split("#")
        if (valueList[0] == 'age_scope') {
          this.classify.ageLevel = ''
          this.classify.courseGrade = valueList[1]
        } else if (valueList[0] == 'class_level') {
          this.classify.courseGrade = ''
          this.classify.ageLevel = valueList[1]
        }
      }

      switch (valueTypeName) {
        case 'courseClassify':
        case 'fieldOfOptions':
          this.classify.courseTheme = '';
          this.classify.courseVersion = '';
          this.classify.ageLevel = ''
          this.classify.courseGrade = ''
          this.classify.scope = ''
          this.getThemeList(() => {
            if (this.searchThemeOptions.length > 0) {
              this.classify.courseTheme = this.searchThemeOptions[0].value;
            } else {
              this.classify.courseTheme = '';
            }
            this.getVersionList(() => {
              if (this.versionOptions.length > 0) {
                this.classify.courseVersion = this.versionOptions[0].value;
              } else {
                this.classify.courseVersion = '';
              }
              this.getScopeList(() => {
                if (this.scopeOptions.length > 0) {
                  let valueList = this.scopeOptions[0].value.split("#")
                  if (valueList[0] == 'age_scope') {
                    this.classify.ageLevel = ''
                    this.classify.courseGrade = valueList[1]
                  } else if (valueList[0] == 'class_level') {
                    this.classify.courseGrade = ''
                    this.classify.ageLevel = valueList[1]
                  }
                  this.classify.scope = this.scopeOptions[0].value;
                } else {
                  this.classify.ageLevel = ''
                  this.classify.courseGrade = ''
                  this.classify.scope = ''
                }
                this.searchCourse();
              });
            })
          })
          break;
        case 'courseTheme':
          this.classify.courseVersion = '';
          this.classify.ageLevel = ''
          this.classify.courseGrade = ''
          this.classify.scope = ''
          this.getVersionList(() => {
            if (this.versionOptions.length > 0) {
              this.classify.courseVersion = this.versionOptions[0].value;
            } else {
              this.classify.courseVersion = '';
            }
            this.getScopeList(() => {
              if (this.scopeOptions.length > 0) {
                let valueList = this.scopeOptions[0].value.split("#")
                if (valueList[0] == 'age_scope') {
                  this.classify.ageLevel = ''
                  this.classify.courseGrade = valueList[1]
                } else if (valueList[0] == 'class_level') {
                  this.classify.courseGrade = ''
                  this.classify.ageLevel = valueList[1]
                }
                this.classify.scope = this.scopeOptions[0].value;
              } else {
                this.classify.ageLevel = ''
                this.classify.courseGrade = ''
                this.classify.scope = ''
              }
              this.searchCourse();
            });
          })
          break;
        case 'courseVersion':
          this.classify.ageLevel = ''
          this.classify.courseGrade = ''
          this.classify.scope = ''
          this.getScopeList(() => {
            if (this.scopeOptions.length > 0) {
              let valueList = this.scopeOptions[0].value.split("#")
              if (valueList[0] == 'age_scope') {
                this.classify.ageLevel = ''
                this.classify.courseGrade = valueList[1]
              } else if (valueList[0] == 'class_level') {
                this.classify.courseGrade = ''
                this.classify.ageLevel = valueList[1]
              }
              this.classify.scope = this.scopeOptions[0].value;
            } else {
              this.classify.ageLevel = ''
              this.classify.courseGrade = ''
              this.classify.scope = ''
            }
            this.searchCourse();
          })
          break;
        case 'scope':
          this.searchCourse();
          break;
        case 'activityType':
          this.searchActivityMenuItem(value);
          break;
        default:
          this.courseOptinons.forEach((item, index) => {
            if (item.id == this.classify.course) {
              this.emitCourseData.course = item;
            }
          });
          this.searchCourseContent({ id: value });
          break;
      }

    },
    /**
     * 搜索课程下的活动目录
     * @param {*} value
     * @return {*}
     */
    searchActivityMenuItem (value) {
      switch (value) {
        case 'all':
          this.searchCourseContentActivityAllList();
          this.$nextTick(() => {
            this.isShow = true
          })
          break;
        case 'jt':
          this.courseData.forEach((item, index) => {
            this.searchCourseContentActivityCatalogueList(item.id, index, 'jt');
          });
          this.$nextTick(() => {
            this.isShow = true
          })
          break;
        case 'jy':
          this.courseData.forEach((item, index) => {
            this.searchCourseContentActivityCatalogueList(item.id, index, 'jy');
          });
          this.$nextTick(() => {
            this.isShow = true
          })
          break;
        default:
          this.courseData.forEach((item, index) => {
            this.searchCourseContentActivityList({ courseThemeId: item.id, activityClassify: value }, index);
          });
          this.$nextTick(() => {
            this.isShow = true
          })
          break;
      }
    },
    handleOpen (key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose (key, keyPath) {
      console.log(key, keyPath);
    },
    /**
     * 查询课程
     */
    searchCourse () {
      let { courseClassify, fieldOfOptions, ageLevel, courseGrade, courseTheme, courseVersion } = this.classify;
      let data = {
        courseClassify: courseClassify,
        courseField: fieldOfOptions,
        courseTheme: courseTheme,
        courseVersion: courseVersion,
        courseLevel: ageLevel,
        courseSuitable: courseGrade
      };
      this.courseData = [];
      for (let key in data) {
        if (data[key] === 'all' || data[key] === '') {
          delete data[key];
        }
      }
      console.log(data)
      this.$api.searchCourse(data)
        .then(result => {
          let { data, code, } = result.data;
          if (code) {
            return;
          }
          data.map((item, index) => {
            if (!index) {
              this.classify.course = item.id.toString();
            }
            item.name = item.courseName;
            item.label = item.id.toString();
            item.value = item.id.toString();
            return item;
          });
          this.defaultOpeneds = [];
          if (data.length === 0) {
            this.classify.course = '0';
            this.activityDetails = null;
            this.activityCatalogueDetails = null;
            this.courseActivityData = [];
            this.emitCourseData.course = {};
            this.emitCourseData.activity = {};
            this.courseOptinons = [];
            this.$nextTick(() => {
              this.isShow = true
            })
          } else {
            this.courseOptinons = data;
            this.$nextTick(() => {
              this.courseOptinons.forEach((item, index) => {
                this.defaultOpeneds.push(index.toString());
                if (item.id == this.classify.course) {
                  this.emitCourseData.course = item;
                }
              });
              if (this.classify.course) {
                this.searchCourseContent({ id: this.classify.course });
              }
            })
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          // this.searchActivityMenuItem(this.classify.activityType);
        });
    },
    /**
     * 选择资源-课程搜索-课程内容
     * fn?
     */
    searchCourseContent (id) {
      this.$api.searchCourseContent(id)
        .then(result => {
          // console.log(result);
          let { code, data } = result.data;
          if (code) {
            return;
          }
          // 设置课程目录id列表
          this.courseIdList = [];
          data.forEach(item => {
            this.courseIdList.push(item.id);
          });
          this.courseData = data;

          this.$nextTick(() => {
            this.searchActivityMenuItem(this.classify.activityType);
          })

          this.$forceUpdate()
        })
        .catch(err => { });
    },
    /**
     * 获取课程活动类型列表
     */
    searchCourseContentActivityList (params, index) {
      this.$api.searchCourseContentActivityList(params)
        .then(result => {
          let { code, data } = result.data;
          // let { code, data } = result.data;
          if (code) {
            return;
          }
          console.log(this.courseActivityData)
          console.log(index)
          if (!this.courseActivityData[index]) {
            this.courseActivityData[index] = [];
          }
          this.$nextTick(() => {
            this.courseActivityData[index] = this.courseActivityData[index].concat(data);
            this.$forceUpdate()
          })
        })
        .catch(err => { });
    },
    /**
     * 获取课程集体活动列表
     */
    searchCourseContentActivityCatalogueList (courseThemeId, index, type) {
      let params = {
        courseThemeId: courseThemeId,
      }
      if (type == 'jt') {
        // params.isOpen = 0
        params.isTeam = 1
      } else if (type == 'jy') {
        params.isOpen = 1
        // params.isTeam = 0
      }
      this.$api.searchCourseContentActivityCatalogueList(params)
        .then(result => {
          let { code, data } = result.data;
          // let { code, data } = result.data;
          if (code) {
            return;
          }
          data.map((item) => {
            item.activityClassify = type
          })
          console.log(this.courseActivityData)
          console.log(index)
          if (!this.courseActivityData[index]) {
            this.courseActivityData[index] = [];
          }
          this.$nextTick(() => {
            this.courseActivityData[index] = this.courseActivityData[index].concat(data);
            this.$forceUpdate()
          })
        })
        .catch(err => { });
    },
    /**
     * 获取课程下所有的活动数据
     */
    searchCourseContentActivityAllList () {
      this.activityOptinons.forEach(item => {
        this.courseData.forEach((item1, index1) => {
          if (item.value === 'jt' || item.value === 'jy') {
            this.searchCourseContentActivityCatalogueList(item1.id, index1, item.value);
          } else {
            this.searchCourseContentActivityList({ courseThemeId: item1.id, activityClassify: item.value }, index1);
          }
        });
      });
    },
    /**
     * 获取课程活动的详情
     */
    searchCourseContentCatalogueActivityDetail (id) {
      this.$api.searchCourseContentCatalogueActivityDetail({ courseThemeCatalogueId: id })
        .then(result => {
          let { code, data } = result.data;
          if (code) {
            return;
          }
          let dataList = []
          if (data && data.length > 0) {
            let showItem = []
            data.map((item) => {
              if (item.contentType != '2') {
                showItem.push(item.id)
              }
            })
            dataList = data.filter((info) => { return info.contentType != '2' })
            this.showItem = showItem
          }
          this.activityCatalogueDetails = dataList;
          // this.$nextTick(() => {
          //   this.isDetailShow = true
          // })
          this.$forceUpdate()
        })
        .catch(err => { });
    },
    /**
     * 获取课程集体活动详情
     * @param {*} id
     * @return {*}
     */
    searchCourseContentActivityDetail (id) {
      this.$api.searchCourseContentActivityDetail({ activityId: id })
        .then(result => {
          let { code, data } = result.data;
          if (code) {
            return;
          }
          this.activityDetails = data && data[0];
          // this.$nextTick(() => {
          //   this.isDetailShow = true
          // })
          this.$forceUpdate()
        })
        .catch(err => { });
    },
    handleCourseData () {

    },
    /**
     * 课程目录列表按钮
     */
    handleMenuItem (scope) {
      let { activityClassify, id } = scope;
      // this.isDetailShow = false
      this.$nextTick(() => {
        // this.activityDetails = null;
        // this.activityCatalogueDetails = null;
        this.emitCourseData.activity = scope;
        if (activityClassify != 'jy' && activityClassify != 'jt') {
          this.activityCatalogueDetails = null;
          this.searchCourseContentActivityDetail(id);
        } else {
          this.activityDetails = null;
          this.searchCourseContentCatalogueActivityDetail(id);
        }
      })
    },
    // 确定资源
    handleCheckCourse () {
      if (!this.emitCourseData.course.id) {
        this.$message.error('请选择课程');
        return;
      }
      if (!this.emitCourseData.activity.id) {
        this.$message.error('请选择活动');
        return;
      }
      this.$emit('selectResourceData', this.emitCourseData);
      this.dialogBeforeClose();
    }
  },
  computed: {
    ...mapState({
      typeOptions: (state) => state.common.courseTypeList,
      areaOptions: (state) => state.common.courseFieldList,
      stepOptions: (state) => state.common.ageScopeList,
      levelOptions: (state) => state.common.courseLevelList,
      themeOptions: (state) => state.common.courseThemeList,
      // versionOptions: (state) => state.common.courseVersionList,
    })
  },

  watch: {
    selectResource () {
      this.dialogVisible = true;
      this.classify.courseClassify = 'all'
      this.classify.fieldOfOptions = 'all'
      this.activityDetails = null
      this.activityCatalogueDetails = null
      // this.classify.courseTheme = this.themeOptions[0].value
      // this.classify.courseVersion = this.versionOptions[0].value
      // this.classify.scope = 'step#'+ this.stepOptions[0].value
      // this.classify.courseGrade = this.stepOptions[0].value
      this.getThemeList(() => {
        if (this.searchThemeOptions.length > 0) {
          this.classify.courseTheme = this.searchThemeOptions[0].value;
        } else {
          this.classify.courseTheme = '';
        }
        this.getVersionList(() => {
          if (this.versionOptions.length > 0) {
            this.classify.courseVersion = this.versionOptions[0].value;
          } else {
            this.classify.courseVersion = '';
          }
          this.getScopeList(() => {
            if (this.scopeOptions.length > 0) {
              let valueList = this.scopeOptions[0].value.split("#")
              if (valueList[0] == 'age_scope') {
                this.classify.ageLevel = ''
                this.classify.courseGrade = valueList[1]
              } else if (valueList[0] == 'class_level') {
                this.classify.courseGrade = ''
                this.classify.ageLevel = valueList[1]
              }
              this.classify.scope = this.scopeOptions[0].value;
            } else {
              this.classify.ageLevel = ''
              this.classify.courseGrade = ''
              this.classify.scope = ''
            }
            this.searchCourse();
          })
        })
      })

      // this.searchCourse();
    },
    classify () {
      console.log(this.classify);
    }
  }
};
</script>

<style lang="scss">
.dialog_resource {
  .dialog_resource_body {
    .el-form {
      padding: 10px 20px;
      margin-bottom: 10px;
      box-shadow: 0 3px 6px #0001;
      .el-form-item {
        padding: 10px 0;
        margin: 0 !important;
        border-bottom: 1px dashed #e9e9e9;
        .el-form-item__label {
          width: auto !important;
          min-width: 100px;
          height: 26px;
          line-height: 26px;
          text-align: left;
        }
        .el-form-item__content {
          display: flex;
        }
      }
    }
  }
  .el-dialog__footer {
    padding-right: 40px;
  }
}
.course_content_wrap {
  .el-menu {
    .el-submenu {
      color: 18px;
      .el-submenu__title {
        padding-right: 30px;
        font-size: 18px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.75);
        .activity-course-title {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 18px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.75);
        }
        .el-submenu__icon-arrow {
          right: 15px;
        }
      }
      &.is-active {
        .el-submenu__title {
          background: #f7f7f7;
          .activity-course-title {
            color: #597ef7;
          }
        }
      }
      .el-menu-item {
        padding-left: 20px !important;
        min-width: 100%;
        &.is-active {
          min-width: 100%;
          color: #00000080;
          font-size: 16px;
          background: #597ef730;
          border-left: 4px solid #597ef7;
          border-radius: 6px 0 0 6px;
        }
      }
    }
  }

  .ccw_content {
    padding: 20px;
    p {
      margin: 10px 0;
      font-size: 16px;
      line-height: 1.6;
    }
    video,
    audio {
      margin: 20px 10px;
    }
    .ccw_content_title {
      display: flex;
      margin-bottom: 20px;
      justify-content: space-between;
    }
    h1 {
      font-size: 26px;
    }
    .ccw_title_next {
      display: flex;
      padding: 20px 0;
      align-items: center;
      font-size: 18px;
      font-weight: bold;
      border-bottom: 1px solid #e9e9e9;
      &::before {
        display: inline-block;
        margin-right: 10px;
        content: '';
        width: 9px;
        height: 20px;
        background: #597ef7;
        border-radius: 6px;
      }
    }
  }
  .ccw_collapse {
    .el-collapse-item__header {
      font-size: 20px;
    }
    .el-collapse-item__arrow {
      font-size: 13px;
    }
    .ccw_collapse_text {
      margin-bottom: 20px;
      p {
        margin: 10px 0;
        font-size: 16px;
        line-height: 1.6;
      }
    }
    .ccw_collapse_videos {
      display: flex;
      flex-wrap: wrap;
      .ccw_collapse_video {
        margin: 0 20px 20px 0;
        width: 326px;
        border-radius: 2px;
        border: 1px solid #e9e9e9;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.09);
        .ccw_collapse_video_box {
          width: 100%;
          height: 245px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 74px;
            height: 74px;
          }
          video {
            width: 100%;
            max-height: 100%;
            margin: 0;
          }
        }
        .ccw_collapse_video_info_box {
          padding: 15px;
          .ccw_collapse_video_name {
            font-size: 18px;
          }
          .ccw_collapse_video_info {
            margin-bottom: 20px;
            color: #8c8c8c;
            font-size: 14px;
          }
          .ccw_collapse_video_date {
            color: #8c8c8c;
            font-size: 14px;
          }
        }
      }
    }
    .ccw_collapse_files {
      display: flex;
      flex-wrap: wrap;
      .ccw_collapse_file {
        margin: 0 20px 20px 0;
        width: 352px;
        // height: 134px;
        // border-radius: 8px;
        border: 1px solid #e8e8e8;
        .ccw_collapse_file_body {
          padding: 0 24px;
          width: 100%;
          height: 100px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          background: #ffffff;
          font-size: 19px;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.85);
          line-height: 1.4;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .ccw_collapse_file_footer {
          padding: 0 10px;
          height: 34px;
          background-color: #f7f9fa;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-top: 0;
          width: calc(100% - 20px);
          min-width: 0;
          img {
            width: 16px;
            height: 16px;
            cursor: pointer;
          }
        }
        .ccw_collapse_file_icon {
          margin-right: 10px;
          width: 30px;
          height: 32px;
          > i {
            display: inline-block;
            width: 100%;
            height: 100%;
            font-size: 16px;
          }
          .excel {
            background: url(../../../assets/img/file-icon-excel.png) no-repeat;
          }
          .pdf {
            background: url(../../../assets/img/file-icon-PDF.png) no-repeat;
          }
          .word {
            background: url(../../../assets/img/file-icon-word.png) no-repeat;
          }
          .ppt {
            background: url(../../../assets/img/file-icon-ppt.png) no-repeat;
          }
        }
      }
    }
    .ccw_collapse_activitys {
      .ccw_collapse_activity {
        font-size: 16px;
        .ccw_collapse_activity_name {
          font-weight: bold;
          line-height: 1.6;
        }
        .ccw_collapse_activity_content {
          p {
            margin: 10px 0;
            line-height: 1.6;
          }
        }
      }
    }
  }
}
</style>
